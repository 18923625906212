import React from "react";

import Layout from "layouts";
import Hero from "./hero.section";
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
      <Helmet title={'MASQ | DOWNLOADS'} defer={false} />
      <Hero />
    </Layout>
  );
};

export default Index;
